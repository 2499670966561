import React from 'react';
import PropTypes from 'prop-types';

import MainNav from './MainNav';
import Logo from './Logo';

import * as Styled from './styles';

const Header = ({ siteTitle }) => (
  <div className="bg-white items-center flex p-4">
    <Logo />
    <MainNav />
  </div>
);

Header.defaultProps = {
  siteTitle: ``
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired
};

export default Header;
